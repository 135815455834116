<template>

  <div class="login-container">

    <h2>登录</h2>
    <form @submit.prevent="login" class="login-form">
      <div class="form-group">
        <label for="username">用户名 : </label>
        <input type="text" id="username" v-model="username" required>
      </div>
      <div class="form-group">
        <label for="password"> 密码 : </label>
        <input type="password" id="password" v-model="password" required>
      </div>
      <button type="submit" class="login-button">登录</button>
    </form>

  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    login() {

      // axios.post('/login', { username: this.username, password: this.password });
      console.log('Login submitted');
      // 在成功登录后更新登录状态，并显示相应的内容

      // if the username and password are correct, set isAuthenticated to true
      if (this.username === 'admin' && this.password === 'admin') {
        this.$root.isAuthenticated = true;
      } else {
        alert('用户名或密码错误');
      }
      // this.$root.isAuthenticated = true;
    }
  }
};
</script>

<style scoped>


.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: scale(1.2);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  min-width: 80px;
}

input[type="text"],
input[type="password"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  flex: 1;
}

.login-button {
  padding: 10px;
  background-color: #24a3ac;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #3e508c;
}

</style>
