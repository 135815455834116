<template>
  <!-- 页面内容 -->
  <div v-if="!hide">
    <div class="content">
      <div class="main">
        <h2>品牌案例上载</h2>

        <el-form ref="form" :model="formData">
          <el-form-item label="企业名称">
            <el-input v-model="formData.companyName" placeholder="填写涉及的企业名称，应与营业执照一致"></el-input>
          </el-form-item>

          <el-form-item label="品牌名称">
            <el-input v-model="formData.brandName"
                      placeholder="请输入品牌名称（可填写企业旗下多个品牌名称，使用空格间隔）"></el-input>
          </el-form-item>

          <el-form-item label="行业类别">
            <el-input v-model="formData.industry" placeholder="填写企业所属的行业类别，如：制造业、服务业等"></el-input>
          </el-form-item>

          <el-form-item label="品牌创建时间">
            <el-input v-model="formData.creationTime" placeholder="填写品牌创建的时间，如有则填写"></el-input>
          </el-form-item>

          <el-form-item label="品牌定位">
            <el-input v-model="formData.positioning" placeholder="填写品牌的市场定位，如：高端、中端、低端等"></el-input>
          </el-form-item>

          <el-form-item label="品牌核心价值观">
            <el-input v-model="formData.coreValues" placeholder="填写品牌的核心价值观，如：品质、创新、服务等"></el-input>
          </el-form-item>

          <el-form-item label="品牌标志">
            <el-upload
                class="upload-demo"
                drag
                action="#"
                :on-change="handleImageUploadLogo"
                :auto-upload="false"
                :limit="1"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将ZIP文件拖到此处或<em>点击上传</em></div>
              <div class="el-upload__text">最多上传<em>1</em>个文件</div>
            </el-upload>
          </el-form-item>

          <el-form-item label="品牌口号">
            <el-input v-model="formData.slogan" placeholder="填写品牌的口号，如有则填写"></el-input>
          </el-form-item>

          <el-form-item label="品牌官方网站">
            <el-input v-model="formData.website" placeholder=" "></el-input>
          </el-form-item>

          <el-form-item label="品牌官微地址">
            <el-input v-model="formData.wechat" placeholder=" "></el-input>
          </el-form-item>

          <el-form-item label="品牌官方抖音">
            <el-input v-model="formData.douyin" placeholder=" "></el-input>
          </el-form-item>

          <el-form-item label="品牌官方小红书">
            <el-input v-model="formData.xiaohongshu" placeholder=" "></el-input>
          </el-form-item>

          <el-form-item label="品牌案例简介">
            <el-input v-model="formData.caseIntro"
                      placeholder="填写品牌案例的简要介绍，包括案例背景、时间、地点等"></el-input>
          </el-form-item>

          <el-form-item label="案例名称">
            <el-input v-model="formData.caseName" placeholder="填写案例的名称，应具有针对性和代表性"></el-input>
          </el-form-item>

          <el-form-item label="案例分类">
            <el-radio-group v-model="formData.category">
              <el-radio label="品牌营销案例">品牌营销案例</el-radio>
              <el-radio label="品牌创新案例">品牌创新案例</el-radio>
              <el-radio label="乡村振兴品牌帮扶案例">乡村振兴品牌帮扶案例</el-radio>
              <el-radio label="品牌国际传播案例">品牌国际传播案例</el-radio>
              <el-radio label="品牌ESG建设案例">品牌ESG建设案例</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="品牌案例详细内容">
            <!--            <el-input v-model="formData.caseContent" type="textarea"-->
            <!--                      placeholder="填写品牌案例的详细内容，包括案例过程、结果、影响等，应具有可读性和可参考性"></el-input>-->
            <el-upload
                class="upload-demo"
                drag
                action="#"
                :on-change="handleImageUploadCase"
                :auto-upload="false"
                :limit="1"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将ZIP文件拖到此处或<em>点击上传</em></div>
              <div class="el-upload__text">最多上传<em>1</em>个文件</div>
            </el-upload>
          </el-form-item>

          <el-form-item label="品牌案例策略">
            <el-input v-model="formData.caseStrategy" type="textarea"
                      placeholder="请填写您的品牌案例分析、目标受众及市场定位等方面的策略。"></el-input>
          </el-form-item>

          <el-form-item label="品牌案例表现">
            <el-input v-model="formData.casePresentation" type="textarea"
                      placeholder="请描述您的品牌案例中，如何通过创意和设计展现品牌特点和优势。"></el-input>
          </el-form-item>

          <el-form-item label="品牌案例传播">
            <el-input v-model="formData.casePromotion" type="textarea"
                      placeholder="请说明您在品牌案例传播过程中，采用的媒体渠道和实施效果。"></el-input>
          </el-form-item>

          <el-form-item label="案例收集人">
            <el-input v-model="formData.collector" placeholder="填写案例收集人的姓名、联系方式等"></el-input>
          </el-form-item>

          <el-form-item label="案例收集时间">
            <el-input v-model="formData.collectionTime" placeholder="填写案例收集的时间，格式为：年/月/日"></el-input>
          </el-form-item>
        </el-form>
        <div class="footer">
          <button @click="showConfirmationDialog" class="btn2">提交</button>
        </div>
        <div v-if="isConfirmationDialogVisible" class="confirmation-dialog">
          <p>确定要提交吗？</p>
          <div class="btn-container">
            <button @click="submitForm" class="saveButton">确认</button>
            <button @click="closeConfirmationDialog" class="btn btn-secondary">取消</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'myForm',
  data() {
    return {
      isConfirmationDialogVisible: false,
      formData: {
        companyName: '',
        brandName: '',
        industry: '',
        creationTime: '',
        positioning: '',
        coreValues: '',
        logo: '',
        slogan: '',
        website: '',
        wechat: '',
        douyin: '',
        xiaohongshu: '',
        caseIntro: '',
        caseName: '',
        category: '',
        caseContent: '',
        caseStrategy: '',
        casePresentation: '',
        casePromotion: '',
        collector: '',
        collectionTime: '',
      }

    };
  },
  methods: {
    submitForm() {
      console.log(this.formData)
      axios.put('https://tc.mc-bedrock.com/', this.formData)
          .then(response => {

            console.log(response.data);
            alert('提交成功！');
            this.$root.isSubmitted = true;
          })
          .catch(error => {
            console.error('提交失败:', error);
            alert('提交失败，请重试！');
          });
    },
    showConfirmationDialog() {
      console.log('显示确认对话框');
      this.isConfirmationDialogVisible = true;
    },
    closeConfirmationDialog() {
      this.isConfirmationDialogVisible = false;
    },
    handleImageUploadLogo(file) {
      //convert to base64 and add to form obj, multiple files can be uploaded
      const reader = new FileReader();
      reader.onload = (e) => {
        this.formData.logo = e.target.result;
      };
      reader.readAsDataURL(file.raw);

    },
    handleImageUploadCase(file) {
      //convert to base64 and add to form obj, multiple files can be uploaded
      const reader = new FileReader();
      reader.onload = (e) => {
        this.formData.caseContent = e.target.result;
      };
      reader.readAsDataURL(file.raw);
    }
  },
};
</script>
<style scoped>
button {
  background-color: #24a3ac;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #3e508c;
}


.confirmation-dialog {
  width: 320px;
  position: fixed;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
}

.confirmation-dialog p {
  margin: 0 0 20px;
}

.confirmation-dialog .btn-container {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.btn2 {
  background-color: #24a3ac;
  color: white;
  padding: 14px 48px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s ease;
  width: 200px;
}

.btn2:hover {
  background-color: #3e508c;
}

.btn-secondary {
  background-color: #ccc;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.btn-secondary:hover {
  background-color: #999;
  color: #555;
}

.footer {
  padding: 24px;
  text-align: center;
}

.el-form {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}


h2 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.el-form-item__label {
  font-weight: 600;
  color: #555;
}


.el-input__inner {
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.el-input__inner:focus {
  border-color: #409eff;
  box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.2);
}


.el-upload-dragger {
  background-color: #f5f7fa;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 180px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-upload-dragger .el-icon-upload {
  font-size: 67px;
  color: #c0c4cc;
  margin: 40px 0 16px;
  line-height: 50px;
}

.el-upload-dragger + .el-upload__tip {
  text-align: center;
}


.el-radio-group {
  display: flex;
  flex-wrap: wrap;
}

.el-radio {
  margin-right: 30px;
  margin-bottom: 15px;
}


</style>

