<template>
  <transition name="fade">
    <div class="success-animation">
      <div class="success-gif-left">
        <img src="../assets/烟花.gif" alt="Success Animation" />
      </div>
      <div class="success-content">
        <h1>提交成功！</h1>
        <div class="vertical-content">
          <img class="success-icon" src="../assets/对号.jpg" alt="Success Icon">
          <br/>
          <div class="tips">
            <p>贵单位提供的案例已经纳入中企智能魔方品牌案例库，我们将会对案例库进行进一步梳理，感谢您的上传。</p>
          </div>
        </div>
      </div>
      <div class="success-gif-right">
        <img src="../assets/烟花.gif" alt="Success Animation" />
      </div>
    </div>
  </transition>
</template>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.success-animation {
  text-align: center;
  margin: 20px;
  font-family: 'Open Sans', sans-serif;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.success-gif-left .success-gif-right .success-content{
  width: 30%;
}
.success-animation h1 {
  color: #333;
}

.success-animation p {
  color: #666;
  /* Allow text to wrap */
  white-space: pre-wrap;
  line-height: 1.5;
}

@keyframes tick {
  0% { transform: scale(1); }
  25% { transform: scale(1.2); }
  50% { transform: scale(1); }
  100% { transform: scale(1); }
}

.success-icon {
  width: 100px;
  margin-top: 20px;
  animation: tick 1s infinite; /* Infinite animation */
}

.success-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.success-gif-left,
.success-gif-right {
  flex: 1;
}

.success-content {
  flex: 3;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertical-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  max-width: 50%;
}
.success-gif-left{
  margin-left: auto;
}
.success-gif-right{
  margin-right: auto;
}
.success-gif-left,
.success-gif-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.success-content img {
  max-width: 50%;
  margin: 10px 0;
}

.success-content h1 {
  font-size: 24px;
  margin-top: 20px;
}

.success-content p {
  font-size: 16px;
  margin-top: 10px;
}
</style>
