<template>
  <div class="content">
    <div class="header">
      <h1><img src="../assets/图标.png"></h1>
      <ul>
        <!-- <li><a href="#">个人资料</a></li>
        <li><a href="#">设置</a></li>
        <li><a href="#">退出</a></li> -->
      </ul>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    name: 'myHeader'
  }
  </script>
  
  <style scoped>
.header {
    background-color: #555;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h1 {
    color: #fff;
    margin: 0;
}

.header ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.header ul li {
    display: inline;
    margin-right: 10px;
}

.header ul li a {
    color: #fff;
    text-decoration: none;
}
  </style>
  