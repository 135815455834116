<template>
  <div id="app">
    <myHeader />
    <template v-if="isAuthenticated && !isSubmitted">
      <myForm />
    </template>
    <template v-else-if="isSubmitted">
      <successAnimation />
    </template>
    <template v-else>
      <LogIn />
    </template>
  </div>
</template>


<script>
import myHeader from './components/myHeader.vue'
import myForm from './components/myForm.vue'
import LogIn from './components/LogIn'
import SuccessAnimation from './components/SuccessAnimation.vue';

export default {
  components: {
    myHeader,
    myForm,
    LogIn ,
    SuccessAnimation
  },
  data() {
    return {
      isAuthenticated: false ,
      isSubmitted: false
    };
  }
}
</script>

<style>
.body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}
/* .content {
    /* margin-left: 250px; */
    /* padding: 20px; */
</style>
